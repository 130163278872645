import React from 'react'

import {Link} from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Carousel from 'nuka-carousel'
// import Json from 'react-json-view'
import Icon from '@mdi/react'
import {mdiGraphql, mdiJson} from '@mdi/js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faApple, faJsSquare, faPython, faReact,} from '@fortawesome/free-brands-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faChartBar,
  faCircle,
  faEye,
  faMobileAlt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import {Col, Container, Responsive, Row, Section,} from '../components/containers/Layout'
import Polaroid, {Photo} from '../components/base/Polaroid'
import {H2, H3, Heading, Paragraph} from '../components/base/Typography'
import macrons from '../media/macrons.jpg'
import littleDamage from '../media/little-damage.jpeg'
import tacos from '../media/tacos.jpeg'
import cola from '../media/cola.jpeg'
import latte from '../media/latte.jpeg'
import hotdog from '../media/hotdogs.jpeg'
import code from '../media/code.jpeg'
import fullTable from '../media/full-table-2.jpeg'
import ItemTag from '../components/item/ItemTag'
import {
  BREAKFAST_RESPONSE,
  EGG_RAMEN,
  EGG_SANDWICH,
  FULL_BREAKFAST,
  LATTE,
  MACARON,
  PAELLA,
  RAMEN_SASHIMI,
  TACOS,
  VISION_RESPONSE,
} from '../data/api-responses'
import Button from '../components/base/Button'
import {List, ListItem} from '../components/containers/List'
import {CustomSolutions} from './index'
import Contact from "../components/Contact";


export const RecognitionExample = ({ image, path, visionResponse, num = 6 } = {}) => (
  <Col style={{ justifyContent: 'center' }}>
    <Row justify="center">
      <Polaroid src={image} path={path} size={300} />
    </Row>
    <Row wrap="wrap" justify="center">
      {visionResponse.items
        .slice(0, num)
        .map(x => (
          <ItemTag
            key={x.item.id}
            item={x.item}
            inline
            style={{ opacity: x.score * 8 }}
          />
        ))}
    </Row>
  </Col>
)


class PatchedCarousel extends Carousel {
  componentDidMount() {
    super.componentDidMount()

    this.timer = setTimeout(() => {
      this.timer = null
      try {
        this.setDimensions(null)
      } catch (err) {
        // sometimes it crashes in chrome, pristine error handling here
      }
    }, 10)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}

let example = Object.assign({}, VISION_RESPONSE)
example.items = example.items.slice(0, 2)

let heirarchyItem = VISION_RESPONSE.items[2]
heirarchyItem.item.children = heirarchyItem.item.children.slice(0, 3)
//example.items[0].children = example.items[0].children.slice(0,2)

const Terminal = styled.div`
  //padding: 20px;
  margin: 5px;
  border-radius: 10px;
  background-color: #2a2a30;
  border: 1px solid #eee;
  color: white;
  overflow: hidden;
`

const TerminalBar = styled.div`
  //background-color: #3e3f40;
  padding: 10px 12px;
`

const TerminalContent = styled.div`
  padding: 20px;
`

const Dot = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 12px;
  background-color: ${({ color = 'green' }) => color};
`

const String = styled.span`
  color: #5f5;
`

const Function = styled.span`
  color: #f9aa27;
  font-weight: bold;
`

const LinkRequestDemo = ({children, ...props}) =>
    <Link
        to="/request-demo/"
        state={{product: 'Food Recognition'}}
        {...props}>
      {children}
    </Link>

const Hero = () => (
  <Section>
    <Container>
      <Col
        style={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
        <Heading large>
          Food <br />Recognition
        </Heading>
        <Paragraph>
          Use the latest deep learning models to <br /> detect and recognize
          thousands of foods.
        </Paragraph>
        {/* <LinkRequestDemo>
          <Button size={'xl'} style={{ margin: '20px 0' }}>
            Book A Demo
          </Button>
        </LinkRequestDemo> */}
      </Col>
    </Container>
    {/*<Container>*/}
    {/*<Row wrap="no-wrap" style={{ alignItems: 'flex-start' }}>*/}
    <PatchedCarousel
      slideWidth={'390px'}
      slidesToScroll={1}
      easing={'easePolyInOut'}
      edgeEasing={'easeLinear'}
      swiping={false}
      dragging={false}
      // cellAlign={'center'}
      // withoutControls
      speed={1000}
      initialSlideHeight={500}
      heightMode={'max'}
      renderBottomCenterControls={undefined}
      renderCenterLeftControls={({ previousSlide, slideCount, currentSlide }) =>
        currentSlide > 0 && (
          <button
            onClick={previousSlide}
            style={{
              backgroundColor: 'white',
              padding: 8,
              borderRadius: 50,
              margin: 15,
              height: 46,
              width: 46,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              outline: 'none',
              border: '1px solid #EEE',
              cursor: 'pointer',
              boxShadow: '1px 2px 20px -3px rgba(0,0,0,.6)',
            }}>
            <FontAwesomeIcon icon={faAngleLeft} size={'2x'} />
          </button>
        )
      }
      renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) =>
        slideCount > currentSlide + 2 && (
          <button
            onClick={nextSlide}
            style={{
              backgroundColor: 'white',
              padding: 10,
              borderRadius: 50,
              margin: 15,
              height: 46,
              width: 46,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              outline: 'none',
              border: '1px solid #EEE',
              cursor: 'pointer',
              boxShadow: '1px 2px 20px -3px rgba(0,0,0,.6)',
            }}>
            <FontAwesomeIcon icon={faAngleRight} size={'2x'} />
          </button>
        )
      }>
      <RecognitionExample
        path={'hanny-naibaho-313771-unsplash.jpg'}
        // image={breakfast}
        visionResponse={BREAKFAST_RESPONSE}
      />
      <RecognitionExample
        path={'macrons.jpg'}
        // image={macrons}
        visionResponse={MACARON} />
      <RecognitionExample
        path={'annie-spratt-464751-unsplash.jpg'}
        // image={paella}
        visionResponse={PAELLA} />
      <RecognitionExample
        path={'latte.jpeg'}
        // image={latte}
        visionResponse={LATTE} />
      <RecognitionExample
        path={'full-breakfast.jpeg'}
        // image={fullBreakfast}
        visionResponse={FULL_BREAKFAST}
        num={10}
      />
      <RecognitionExample
        path={'ramen_sashimi.jpeg'}
        // image={ramenSashimi}
        visionResponse={RAMEN_SASHIMI}
        num={9}
      />
      <RecognitionExample
        path={'tacos.jpeg'}
        // image={tacos}
        visionResponse={TACOS}
        num={9} />
      <RecognitionExample
        path={'egg-ramen.jpeg'}
        // image={eggRamen}
        visionResponse={EGG_RAMEN} num={8} />
      <RecognitionExample
        path={'egg-sandwich.jpeg'}
        // image={eggSandwich}
        visionResponse={EGG_SANDWICH}
        num={8}
      />
    </PatchedCarousel>
    {/*</Row>*/}
    {/*</Container>*/}
  </Section>
)

const ThousandsOfFoods = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse} wide>
      <Col style={{ alignSelf: 'flex-start' }}>
        <H2>Recognize Any Food</H2>
        <Paragraph narrow>
          Our models are trained on millions of images and can recognize
          thousands of the most common foods.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Dishes</H3>
            <Paragraph narrow>
              Recognize commonly eaten dishes, from paninis to paellas.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Ingredients</H3>
            <Paragraph narrow>
              Detect dish ingredients to refine results and create unlimited
              combinations.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Whole Foods</H3>
            <Paragraph narrow>
              Detect whole foods like apples, bananas and nuts.
            </Paragraph>
          </ListItem>
        </List>
        {/*<Link to={'/food-recognition'}>*/}
        {/*<Button style={{ margin: 0 }}>Learn More</Button>*/}
        {/*</Link>*/}
      </Col>
      <Col className={'sm-full'}>
        <Responsive>
          {size => (
            <Polaroid
              src={fullTable}
              size={size ? Math.max(250, Math.min(300, size.width / 3)) : 300}
            />
          )}
        </Responsive>
      </Col>
      <Col style={{ alignSelf: 'flex-start' }}>
        <H2>With the Highest Accuracy</H2>
        <Paragraph narrow>
          We combine a lot of data, the latest deep learning models and
          contextual information to provide the highest accuracy.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Recognize Past Meals</H3>
            <Paragraph narrow>
              Automatically train models to recognize previously eaten meals
              with human level accuracy.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Improve Suggestions Interactively</H3>
            <Paragraph narrow>
              Get updated suggestions with each prediction that the user
              confirms with our interactive input API.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Always Improving</H3>
            <Paragraph narrow>
              Models improve for your specific use case with each image that you
              process.
            </Paragraph>
          </ListItem>
        </List>
        {/*<Link to={'/food-recognition'}>*/}
        {/*<Button style={{ margin: 0 }}>Learn More</Button>*/}
        {/*</Link>*/}
      </Col>
    </Container>
  </Section>
)

const LearnsAsYouUseIt = ({ reverse }) => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container reverse={reverse}>
      <Col>
        <H2>Enhanced For Your Use Case</H2>
        <Paragraph narrow>
          Automatically learns to recognize previously submitted meals and
          improves as you use it.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Learns to Recognize Past Meals</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
          <ListItem>
            <H3>Refine Results</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
          <ListItem>
            <H3>Uses Context</H3>
            <Paragraph narrow>afadsfas</Paragraph>
          </ListItem>
        </List>
        {/* <LinkRequestDemo>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </LinkRequestDemo> */}
      </Col>
      <Row className={'sm-full'} wrap="no-wrap">
        <div>
          <Responsive>
            {size => (
              <Polaroid
                src={hotdog}
                size={size ? Math.max(250, Math.min(300, size.width / 3)) : 300}
              />
            )}
          </Responsive>
        </div>
        {/*<div style={{padding: 5}}>*/}
        {/*{VISION_RESPONSE.items*/}
        {/*.slice(0, 6)*/}
        {/*.map(x => (*/}
        {/*<ItemTag*/}
        {/*key={x.item.id}*/}
        {/*item={x.item}*/}
        {/*inline*/}
        {/*style={{opacity: x.score * 8}}*/}
        {/*/>*/}
        {/*))}*/}
        {/*</div>*/}
      </Row>
    </Container>
  </Section>
)

const CustomModels = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container style={{ flexWrap: 'wrap-reverse' }}>
      <Col>
        <H2>Train Custom Models</H2>
        <Paragraph narrow>
          Use our technology to train models to recognize specific foods or
          attributes.
        </Paragraph>
        <List>
          <ListItem>
            <H3>Recognize Specific Dishes</H3>
            <Paragraph narrow>
              Train models to recognize specific dishes with just a few example
              images. Improve the models even more after you deploy them.
            </Paragraph>
          </ListItem>
          <ListItem>
            <H3>Detect Custom Categories and Attributes</H3>
            <Paragraph narrow>
              Annotate images with custom properties and train models to predict
              them.
            </Paragraph>
          </ListItem>
        </List>
        {/* <LinkRequestDemo>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </LinkRequestDemo> */}
      </Col>
      <Row className={'sm-full'} wrap="no-wrap">
        <Photo
          src={littleDamage}
          style={{
            maxHeight: 600,
          }}
        />
        {/*<Photo*/}
        {/*src={blackBurger}*/}
        {/*style={{*/}
        {/*maxHeight: 600,*/}
        {/*}}*/}
        {/*/>*/}
      </Row>
    </Container>
  </Section>
)

const PackagedProducts = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container>
      <Row className={'sm-full'} wrap="no-wrap">
        <div>
          <Responsive>
            {size => (
              <Photo
                src={cola}
                style={{ maxWidth: 400 }}
                // size={size ? Math.max(250, Math.min(300, size.width / 3)) : 300}
              />
            )}
          </Responsive>
        </div>
        {/*<div style={{ padding: 5 }}>*/}
        {/*{VISION_RESPONSE.items*/}
        {/*.slice(0, 6)*/}
        {/*.map(x => (*/}
        {/*<ItemTag*/}
        {/*key={x.item.id}*/}
        {/*item={x.item}*/}
        {/*inline*/}
        {/*style={{ opacity: x.score * 8 }}*/}
        {/*/>*/}
        {/*))}*/}
        {/*</div>*/}
      </Row>
      <Col>
        <H2>Packaged Products</H2>
        <Paragraph narrow>
          Use our image recognition models to analyze what your users eat,
          determine what's trending and make your images easier to find.
        </Paragraph>
        {/* <LinkRequestDemo>
          <Button style={{ margin: 0 }}>Learn More</Button>
        </LinkRequestDemo> */}
      </Col>
    </Container>
  </Section>
)

const MetaData = () => (
  <Section>
    <Container wide style={{ alignItems: 'flex-start' }}>
      <Col style={{ flex: 1.5 }}>
        <Heading>
          Make the most of <br />the predictions with <br />the Knowledge Graph.
        </Heading>
        <Paragraph large>
          Predictions are nodes in the knowledge graph so you can reason about
          them.
        </Paragraph>
      </Col>
      <Row pad={false}>
        <Col>
          <H2>Relationships</H2>
          <Paragraph narrow>
            Go up or down the relationship hierarchy to tell that "chicken" is a
            type of "meat" and let user's refine it to "grilled chicken" for
            more acurate nutritional estimates.
          </Paragraph>
        </Col>
        <Col>
          <H2>Nutrition Info</H2>
          <Paragraph narrow>
            Most predicted items include full nutritional details, ingredients
            and multiple serving sizes so you can calculate caloric content.
          </Paragraph>
        </Col>
      </Row>
      <Row pad={false}>
        <Col>
          <H2>Categorizations</H2>
          <Paragraph narrow>
            Check categorical info such as cuisine, dish type, flavor and
            preparation method.
          </Paragraph>
        </Col>
        <Col>
          <H2>External References</H2>
          <Paragraph narrow>
            Nodes include references to external data sources such as Wikidata
            and USDA Nutrition Database.
          </Paragraph>
        </Col>
      </Row>
    </Container>
  </Section>
)

const API = () => (
  <Section style={{ backgroundColor: 'white' }}>
    <Container wide>
      <Col>
        <H2>Integrate In Minutes</H2>
        <Paragraph>👴🏻💦 Developers, Developers, Developers!</Paragraph>
        <List>
          <ListItem>
            <Icon path={mdiJson} size={1.5} color={'#77F'} />
            <H3>REST API</H3>
            <Paragraph narrow>
              Use our JSON based REST API to process images using any
              programming language.
            </Paragraph>
          </ListItem>
          {/*<ListItem>*/}
          {/*  <Icon path={mdiGraphql} size={1.5} color={'#F5F'} />*/}
          {/*  <H3>GraphQL</H3>*/}
          {/*  <Paragraph narrow>*/}
          {/*    Use GraphQL to fetch all of the data you need in a single query.*/}
          {/*    Combine with Apollo for caching.*/}
          {/*  </Paragraph>*/}
          {/*</ListItem>*/}
          <ListItem>
            <FontAwesomeIcon
              icon={faPython}
              size={'3x'}
              color={'#77E'}
              style={{ padding: 6 }}
            />
            <FontAwesomeIcon
              icon={faJsSquare}
              size={'3x'}
              color={'#FE6'}
              style={{ padding: 6 }}
            />
            <FontAwesomeIcon
              icon={faApple}
              size={'3x'}
              style={{ padding: 6 }}
            />
            {/*<FontAwesomeIcon*/}
            {/*icon={faAndroid}*/}
            {/*size={'3x'}*/}
            {/*color={'#4D4'}*/}
            {/*style={{ padding: 6 }}*/}
            {/*/>*/}
            <H3>Fully Featured Clients</H3>
            <Paragraph narrow>
              Use our fully featured clients to integrate image recognition into
              your app in as little as 4 lines of code.
            </Paragraph>
          </ListItem>

          <ListItem>
            <FontAwesomeIcon
              icon={faReact}
              size={'3x'}
              color={'#66E'}
              style={{ padding: 6 }}
            />
            <H3>UI Components</H3>
            <Paragraph narrow>
              Use our customizable components to speed up UI integration for common
              user flows.
            </Paragraph>
          </ListItem>
        </List>
      </Col>

      <Col style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Terminal>
          <TerminalBar>
            <Dot color={'#F55'} />
            <Dot color={'#ffe95a'} />
            <Dot color={'#5F5'} />
          </TerminalBar>
          <TerminalContent style={{ minHeight: 200 }}>
            <code>import biteai</code>
            <br />
            <br />
            <code>response = biteai.recognize(image)</code>
            <br />
            <br />
            {/*<Json src={VISION_RESPONSE.items.slice(0, 6)} theme={'tomorrow'}/>*/}
            <code>
              print(response[<String>'items'</String>][0][<String>
                'name'
              </String>])
            </code>
            <br />
            <br />
            {/*<Json src={VISION_RESPONSE.items.slice(0, 6)} theme={'tomorrow'}/>*/}
            <code>
              > <String>"Hot Dog"</String>
            </code>
          </TerminalContent>
        </Terminal>

        {/*<Polaroid src={fullBreakfast} size={300} />*/}

        {/*<Photo src={code} style={{ maxWidth: 400 }} />*/}
      </Col>
    </Container>
  </Section>
)

const UseCases = () => (
  <Section>
    <Container wide style={{ alignItems: 'flex-start' }}>
      <Row pad={false}>
        <Col align={'center'} justify={'center'}>
          {/*<span className="fa-layers fa-fw fa-4x">*/}
          {/*<FontAwesomeIcon icon={faCircle} color="green" />*/}
          {/*<FontAwesomeIcon icon={faMobileAlt} inverse transform="shrink-8" />*/}
          {/*</span>*/}
          <div className="fa-stack fa-3x" style={{ marginBottom: 20 }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={'fa-stack-2x'}
              // size={'6x'}
              color={'#EEF'}
              // style={{ padding: 6 }}
            />
            <FontAwesomeIcon
              icon={faMobileAlt}
              className={'fa-stack-1x'}
              // size={'4x'}
              color={'#565dff'}
              // style={{ padding: 6 }}
            />
          </div>
          <H2>
            Add Food Logging <br /> to Your App
          </H2>
          <Paragraph narrow>
            Get a better picture of your users by enabling them to quickly track
            what they're eating.
          </Paragraph>
        </Col>

        <Col>
          {/*<FontAwesomeIcon*/}
          {/*icon={faSearch}*/}
          {/*size={'4x'}*/}
          {/*color={'#565dff'}*/}
          {/*style={{ padding: 6 }}*/}
          {/*/>*/}

          <span className="fa-stack fa-3x" style={{ marginBottom: 20 }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={'fa-stack-2x'}
              // size={'6x'}
              color={'#EEF'}
              // style={{ padding: 6 }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className={'fa-stack-1x'}
              // size={'4x'}
              color={'#565dff'}
              // style={{ padding: 6 }}
            />
          </span>
          <H2>
            Improve Search <br />and Discovery
          </H2>
          <Paragraph narrow>
            Analyze your images to expand your search index with higher level
            concepts. Keep users engaged with similar item recommendations.
          </Paragraph>
        </Col>
      </Row>

      <Row pad={false}>
        <Col>
          {/*<FontAwesomeIcon*/}
          {/*icon={faEye}*/}
          {/*size={'4x'}*/}
          {/*color={'#565dff'}*/}
          {/*style={{ padding: 6 }}*/}
          {/*/>*/}
          <span className="fa-stack fa-3x" style={{ marginBottom: 20 }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={'fa-stack-2x'}
              // size={'6x'}
              color={'#EEF'}
              // style={{ padding: 6 }}
            />
            <FontAwesomeIcon
              icon={faEye}
              className={'fa-stack-1x'}
              // size={'4x'}
              color={'#565dff'}
              // style={{ padding: 6 }}
            />
          </span>
          <H2>Moderate and Organize User Submitted Content</H2>
          <Paragraph narrow>
            Verify that user submitted images match the description and group
            existing images by dish or ingredients.
          </Paragraph>
        </Col>

        <Col>
          {/*<FontAwesomeIcon*/}
          {/*icon={faChartBar}*/}
          {/*size={'4x'}*/}
          {/*color={'#565dff'}*/}
          {/*style={{ padding: 6 }}*/}
          {/*/>*/}
          <span className="fa-stack fa-3x" style={{ marginBottom: 20 }}>
            <FontAwesomeIcon
              icon={faCircle}
              className={'fa-stack-2x'}
              // size={'6x'}
              color={'#EEF'}
              // style={{ padding: 6 }}
            />
            <FontAwesomeIcon
              icon={faChartBar}
              className={'fa-stack-1x'}
              // size={'4x'}
              color={'#565dff'}
              // style={{ padding: 6 }}
            />
          </span>
          <H2>Analyze Market Trends</H2>
          <Paragraph narrow>
            Make sense of unstructured data at scale by Analyze data at scale
            and understand trends at a deeper level.
          </Paragraph>
        </Col>
      </Row>
    </Container>
  </Section>
)

const RecognitionPage = () => (
  <Layout>
    <Helmet
      title={'Bite AI - Food Recognition API'}
      meta={[
        {
          name: 'description',
          content: 'Food Recognition API',
        },
        {
          property: 'og:description',
          content: 'Food Recognition API',
        },
        {
          name: 'keywords',
          content:
            'food recognition, api, deep learning, image recognition,' +
            'food recognition api, food search, food image recognition,' +
            ' food knowledge graph',
        },
      ]}
    />
    <Hero />
    <ThousandsOfFoods />
    {/*<LearnsAsYouUseIt reverse />*/}
    <CustomModels />
    {/*<PackagedProducts />*/}

    <MetaData />

    <API />
    <UseCases />
    <CustomSolutions />
    <Contact product={"Food Recognition"}/>
  </Layout>
)

export default RecognitionPage
